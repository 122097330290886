import {
    SET_CHANNEL_SOURCES,
    SET_FEEDBACK_CODES,
    SET_KNOWLEDGE_QUESTION_TYPES,
    SET_LANGUAGES,
    SET_RELATION_TYPES,
    SET_SKILL_TYPES,
    SET_QUESTION_TYPES,
    SET_SUBJECTS,
    SET_ORG_LIST,
    SET_ZONE_LIST,
    SET_QUESTION_RELATION_TYPES,
    SET_PROMPT_TPL,
    SET_EXERCISES,
    SET_AREA,
    SET_EXERCISE_TYPE,
    SET_KV,
    SET_CONSTANTS,
    SET_QUESTION
} from 'actions/types'

export const setChannelSources = (payload) => {
    return {
        type: SET_CHANNEL_SOURCES,
        payload
    }
}

export const setFeedbackCodes = (payload) => {
    return {
        type: SET_FEEDBACK_CODES,
        payload
    }
}

export const setLanguages = (payload) => {
    return {
        type: SET_LANGUAGES,
        payload
    }
}
export const setSubjects = (payload) => {
    return {
        type: SET_SUBJECTS,
        payload
    }
}

export const setKnowledgeQuestionTypes = (payload) => {
    return {
        type: SET_KNOWLEDGE_QUESTION_TYPES,
        payload
    }
}

export const setRelationTypes = (payload) => {
    return {
        type: SET_RELATION_TYPES,
        payload
    }
}
export const setQuestionType = (payload) => {
    return {
        type: SET_QUESTION_TYPES,
        payload
    }
}


export const setSkillTypes = (payload) => {
    return {
        type: SET_SKILL_TYPES,
        payload
    }
}
export const setOrgList = (payload) => {
    return {
        type: SET_ORG_LIST,
        payload
    }
}
export const setZoneList = (payload) => {
    return {
        type: SET_ZONE_LIST,
        payload
    }
}
export const setQuestionRelationTypes = (payload) => {
    return {
        type: SET_QUESTION_RELATION_TYPES,
        payload
    }
}

export const setPromptTpl = (payload) => {
    return {
        type: SET_PROMPT_TPL,
        payload
    }
}
export const setExercises = (payload) => {
    return {
        type: SET_EXERCISES,
        payload
    }
}
export const setArea = (payload) => {
    return {
        type: SET_AREA,
        payload
    }
}

export const setExerciseType = (payload) => {
    return {
        type: SET_EXERCISE_TYPE,
        payload
    }
}
export const setKV = (payload) => {
    return {
        type: SET_KV,
        payload
    }
}

export const setConstants = (payload) => {
    return {
        type: SET_CONSTANTS,
        payload
    }
}
export const setQuestion= (payload) => {
    return {
        type: SET_QUESTION,
        payload
    }
}





