/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2020/9/4
 */

import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import {
    AppstoreOutlined,
    CalculatorOutlined, ClusterOutlined,
    CustomerServiceOutlined,
    DeploymentUnitOutlined,
    FileDoneOutlined,
    HomeOutlined,
    LogoutOutlined,
    ProjectOutlined,
    ReadOutlined,
    BuildOutlined,
    BookOutlined,
    ContainerOutlined,
    UnorderedListOutlined
} from '@ant-design/icons'
import Header from './Header'
import styles from './Base.module.less'

const { Sider } = Layout

const getName = () => {
    const subjectID = +(localStorage.getItem('subject_id'))
    return subjectID !== 99 ? 'K12教材' : '课程'
}

const getMenus = () => {
    const menus = [
        {
            path: '/dashboard',
            name: '选择科目',
            icon: <HomeOutlined />
        },
        {
            path: '/outlines',
            name: getName(),
            icon: <AppstoreOutlined />
        },
        {
            path: '/common-outlines',
            name: '普通课程',
            icon: <AppstoreOutlined />
        },
        {
            path: '/school-outlines',
            name: '校本课程',
            icon: <AppstoreOutlined />
        },
        {
            path: '/level-outlines',
            name: '关卡管理',
            icon: <AppstoreOutlined />
        },
        {
            path: '/prompt-tpl',
            name: '内容模板',
            icon: <BuildOutlined />
        },
        {
            path: '/training',
            name: '学员任务',
            icon: <BuildOutlined />
        },
        // {
        //     path: '/grammar',
        //     name: '语法',
        //     icon: <DeploymentUnitOutlined />,
        // },
        {
            path: '/knowledge',
            name: '卡片库',
            icon: <DeploymentUnitOutlined />
        },
        {
            path: '/skill',
            name: '能力库',
            icon: <CalculatorOutlined />
        },
        // {
        //     path: '/paper',
        //     name: '试卷管理',
        //     icon: <UnorderedListOutlined />,
        // },
        // {
        //     path: '/question_types',
        //     name: '题型管理',
        //     icon: <ContainerOutlined />,
        // },
        {
            path: '/question_type_map',
            name: '题型映射',
            icon: <ContainerOutlined />,
        },
        {
            path: '/papers',
            name: '试卷管理',
            icon: <ClusterOutlined />
        },
        {
            path: '/questions',
            name: '测试题库',
            icon: <UnorderedListOutlined />
        },
        {
            path: '/sign-out',
            name: '退出',
            icon: <LogoutOutlined />
        }
    ]
    const subjectID = +window.localStorage.getItem('subject_id')
    if (subjectID === 3) {
        menus.splice(1, 0, {
            path: '/words',
            name: '单词',
            icon: <FileDoneOutlined />
        })
        menus.splice(4, 0, {
            path: '/english_listening',
            name: '英语听力',
            icon: <CustomerServiceOutlined />
        })
        menus.splice(11, 0, {
            path: '/exercises',
            name: '练习册',
            icon: <BookOutlined />
        })
        menus.splice(12, 0, {
            path: '/exercises-for-paper',
            name: '试卷库',
            icon: <BookOutlined />
        })
    }
    if (subjectID === 1) {
        menus.splice(1, 0, {
            path: '/chinese_words',
            name: '词典',
            icon: <FileDoneOutlined />
        })
        menus.splice(4, 0, {
            path: '/classical_chinese_reading',
            name: '文言文阅读',
            icon: <ProjectOutlined />
        })
        menus.splice(5, 0, {
            path: '/modern_chinese_reading',
            name: '现代文阅读',
            icon: <ReadOutlined />
        })
    }
    return menus
}


function Base(props) {
    const { pathname, search } = useLocation()


    const [selectedKey, setSelectedKey] = useState('')

    useEffect(() => {
        const _menus = getMenus()
        const selectedMenu = _menus.find(m => {
            const reg = /^\/([^\/]*).*$/
            return pathname.replace(reg, '$1') === m.path.replace(reg, '$1')
        })
        if (selectedMenu) {
            setSelectedKey(selectedMenu.path)
            document.title = selectedMenu.name
        }
    }, [pathname])

    const menuItems = useMemo(() => {
        const _menus = getMenus()
        return _menus.map((menu) => {
            const { path, name, icon } = menu
            return <Menu.Item key={path} icon={icon} title={name}>
                <Link to={path}>{name}</Link>
            </Menu.Item>
        })
    }, [])
    const handleCollapse = useCallback(() => {

    }, [])

    return (
        <div style={{ height: '100%' }}>
            <Header />
            <div className={styles.layout}>
                <Sider
                    collapsible
                    defaultCollapsed={search.includes('collapsed=1')}
                    onCollapse={handleCollapse}
                    className={styles.sider}
                >
                    <Menu theme="dark" selectedKeys={selectedKey} mode="inline">
                        {menuItems}
                    </Menu>
                </Sider>
                <div className={styles.contentWrap}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Base
